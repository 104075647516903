/* @jsx mdx */
  import React from 'react'
  import { mdx } from '@mdx-js/react'
  /* @jsx mdx */
import Home from './home'
import TopicList from './topiclist'
import Editor from './../../components/editor';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Home mdxType="Home" />
    <blockquote>
      <p parentName="blockquote">{`Implement a polyfill for Promises in the browser which works for ie8`}</p>
    </blockquote>
    <p>{`Okay, so not knowing this question is totally fine. It does not make you a bad developer.
More over most of the times if you are asking to implement something like this, you'd
probably copy something from Stack overflow or some blog site on Google. I realise
people may get intimidated by core JS questions during interviews, and not knowing some of it at that moment
isn't a testament to how you are . Nevertheless a company's bench mark
are such questions.`}</p>
    <p>{`The questions have an editor button, which will open a VS code editor, and let you try out a few questions.`}</p>
    <Editor mdxType="Editor" />
    <h3>{`Index`}</h3>
    <TopicList mdxType="TopicList" />
    </MDXLayout>;
}

MDXContent.isMDXComponent = true;
  